import React from 'react';
import Slider from 'react-slick';
import Skeleton from 'react-loading-skeleton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';

const HomeSlider = ({
  title,
  items,
  loading,
  imageLoading,
  renderLikeIcon,
  language,
  navigate,
  settings,
}) => {
  const {fontFamilies} = useFontFamilyByLanguage();

  const styles = {
    BannerHeading: {
      fontFamily: fontFamilies.semiBold,
      fontSize: '16px',
    },
    BannerContainer: {
      marginLeft: '-35px',
      marginRight: '-35px',
      color: 'rgb(30, 30, 30)',
    },
    BannerImage: {
      width: '70vw',
      height: '180px',
      borderRadius: '20px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      position: 'relative', // Add position relative for the overlay
    },
    BannerTitle: {
      width: '70vw',
      fontFamily: fontFamilies.regular,
      fontSize: '12px',
      margin: '10px',
    },
    ImageContainer: {
      top: '138px',
      left: '22px',
      width: '329px',
      height: '118px',
      backgroundImage: 'url(./image.png)',
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },
    Icon: {
      color: '#030303',
      fill: '#030303',
      width: '40px',
      height: '40px',
    },
    homeContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '60px', // Adjust this value to match your TopNav's actual height plus some margin
    },
    greeting: {
      fontFamily: fontFamilies.semiBold,
      textAlign: 'center',
      margin: '20px 0',
    },
    banner: {
      width: '100%',
      margin: '20px 0',
      // other styles for the banner
    },
    whatsNewSection: {
      width: '100%',
      // other styles for the what's new section
      fontFamily: fontFamilies.semiBold,
    },
    activitiesSection: {
      width: '100%',
      // other styles for the activities section
    },
    sectionHeader: {
      fontFamily: fontFamilies.semiBold,
      padding: '10px 0',
    },
    interactionOverlay: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '-30px',
      paddingRight: '60px',
      color: '#fff', // Set the icon and text color to white for better visibility
    },
    interactionItem: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: '10px',
      zIndex: '1',
    },
    spinnerContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '70vw',
      height: '100vh', // Set to full screen height
      backgroundColor: '#f3f3f3',
      borderRadius: '20px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      position: 'relative', // Add position relative for the overlay
    },
  };

  return (
    <div style={styles.BannerHeading}>
      <h2>{title}</h2>
      {loading ? (
        <Skeleton height={150} width={200} count={3} />
      ) : (
        <Slider {...settings} style={styles.BannerContainer}>
          {items.map(item => (
            <div key={item._id}>
              {imageLoading[item._id] && (
                <div style={styles.spinnerContainer}>
                  <div className="spinner"></div>
                </div>
              )}
              <img
                src={item.coverImg[language]}
                alt={item.title[language]}
                style={{
                  ...styles.BannerImage,
                  display: imageLoading[item._id] ? 'none' : 'block',
                }}
                onClick={() =>
                  navigate(`/article/${item._id}`, {
                    state: {showBackIcon: true},
                  })
                }
              />
              <div style={styles.interactionOverlay}>
                <div style={styles.interactionItem}>
                  <VisibilityIcon /> {item.readUsers.length}
                </div>
                <div style={styles.interactionItem}>
                  {renderLikeIcon(item._id)} {item.likedUsers.length}
                </div>
              </div>
              <div style={styles.BannerTitle}>
                <h3 style={{margin: '0px'}}>{item.title[language]}</h3>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default HomeSlider;
