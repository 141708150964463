import React, {useState, useEffect, useContext} from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import {useNavigate} from 'react-router-dom';
import HomeSlider from '../components/HomeSlider';
import Slider from 'react-slick';
import {ReactSVG} from 'react-svg';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import NotificationsIcon from '@mui/icons-material/Notifications';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BottomNav from '../Navigation/BottomNav';
import {LanguageContext} from '../contexts/LanguageProvider';
import {useFontFamilyByLanguage} from '../hooks/useFontFamilyByLanguage';
import {translate} from '../utils/translate';

const tutorialImages = [
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial1.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial2.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial3.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial4.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial5.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial6.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial7.png`,
  `${process.env.REACT_APP_CDN_URI}/cma-${process.env.REACT_APP_ENV}/tutorial8.png`,
];

function Home() {
  const navigate = useNavigate();
  const {fontFamilies} = useFontFamilyByLanguage();
  const {language} = useContext(LanguageContext);

  const [userInfo, setUserInfo] = useState(Cookies.get('userInfo') || {});
  const [whatsNewItems, setWhatsNewItems] = useState([]);
  const [activityItems, setActivityItems] = useState([]);
  const [likedArticles, setLikedArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showTutorial, setShowTutorial] = useState(userInfo.firstTimeLogin);
  const [tutorialIndex, setTutorialIndex] = useState(0);
  const [imageLoading, setImageLoading] = useState({});

  useEffect(() => {
    try {
      setUserInfo(JSON.parse(Cookies.get('userInfo')));
    } catch {
      null;
    }
    const isNative = navigator.userAgent.includes('wv');
    sessionStorage.setItem('isNative', isNative ? 'true' : 'false');

    const fcmToken = sessionStorage.getItem('pushNotificationToken');

    if (isNative && fcmToken) {
      sendPushTokenToServer(fcmToken);
    }

    const fetchUserInfo = async () => {
      try {
        // No need to fetch the token from localStorage, as cookies handle it automatically
        const response = await axios.get(
          `${process.env.REACT_APP_API_URI}/api/auth/getUserInfo`,
          {withCredentials: true}, // Ensure cookies (including the token) are sent with the request
        );

        setUserInfo(JSON.parse(Cookies.get('userInfo')));

        // Set the user info and points from the response
        //setUserInfo(response.data.userInfo);
        //setPoints(response.data.userInfo.points);

        // Check if it's the first time login
        if (response.data.userInfo.firstTimeLogin) {
          setShowTutorial(true);
        }

        // No need to set token or userInfo in localStorage anymore as it's stored in cookies
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    };

    fetchUserInfo();

    if (!isNative) {
      initializePushNotifications();
    }
  }, []);

  useEffect(() => {
    const fetchWhatsNewItems = async () => {
      try {
        const response = await axios.get(`/api/article/list?type=news`);
        setWhatsNewItems(response.data);
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error('Error fetching new items:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    };

    fetchWhatsNewItems();
  }, [navigate]);

  // Function to send the push notification token to the server
  function sendPushTokenToServer(pushNotificationToken) {
    const token = localStorage.getItem('token');
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/auth/subscribe`,
        {
          isNative: navigator.userAgent.includes('wv'),
          fcmToken: pushNotificationToken,
        }, // Use pushNotificationToken instead of subscription object
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(response => {
        if (!response.data.success && response.status !== 304) {
          throw new Error('Bad response from server.');
        }
      })
      .catch(error => {
        console.error(
          'Error sending push notification token to server:',
          error,
        );
      });
  }

  // Function to initialize push notifications
  function initializePushNotifications() {
    // Check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.');
      return;
    }

    // Check the current Notification permission status
    if (Notification.permission === 'granted') {
      // The user has already granted permission, you can subscribe the user
      subscribeUser();
    } else if (Notification.permission === 'denied') {
      // The user has denied permission
      console.log('User has denied notifications.');
    } else {
      // The user hasn't been asked yet or they have dismissed the prompt
      requestPermission();
    }
  }

  // Function to request notification permission
  function requestPermission() {
    Notification.requestPermission().then(permission => {
      if (permission === 'granted') {
        // The user granted permission
        subscribeUser();
      } else if (permission === 'denied') {
        // The user denied permission
        console.log('User denied notifications.');
      } else {
        // The user dismissed the permission prompt
        console.log('User dismissed the permission prompt.');
      }
    });
  }

  // Function to subscribe the user to push notifications
  function subscribeUser() {
    if ('serviceWorker' in navigator && 'PushManager' in window) {
      navigator.serviceWorker.ready.then(function (registration) {
        const applicationServerKey = urlB64ToUint8Array(
          process.env.REACT_APP_VAPID_PUBLIC_KEY,
        );
        registration.pushManager
          .subscribe({
            userVisibleOnly: true,
            applicationServerKey: applicationServerKey,
          })
          .then(function (subscription) {
            console.log('User is subscribed:', subscription);
            sendSubscriptionToServer(subscription);
          })
          .catch(function (err) {
            console.log('Failed to subscribe the user:', err);
          });
      });
    }
  }

  // Helper function to convert VAPID key
  function urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  // Function to send the subscription object or push token to your server
  function sendSubscriptionToServer(subscription) {
    const token = localStorage.getItem('token');
    axios
      .post(
        `${process.env.REACT_APP_API_URI}/api/auth/subscribe`,
        {subscription},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(response => {
        if (
          !response.data.success &&
          response.status !== 201 &&
          response.status !== 304
        ) {
          throw new Error('Bad response from server.');
        }
      })
      .catch(error => {
        console.error('Error sending subscription to server:', error);
      });
  }

  useEffect(() => {
    const fetchActivityItems = async () => {
      try {
        const response = await axios.get(`/api/article/list?type=activities`);
        setActivityItems(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching activity items:', error);
        if (error.response && error.response.status === 401) {
          navigate('/login');
        }
      }
    };

    fetchActivityItems();
  }, [navigate]);

  const handleLike = async articleId => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/article/like/${articleId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setLikedArticles(prevLikedArticles => [...prevLikedArticles, articleId]);
    } catch (err) {
      console.error('Error liking article:', err);
    }
  };

  const handleUnlike = async articleId => {
    const token = localStorage.getItem('token');
    if (!token) {
      navigate('/login');
      return;
    }

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URI}/api/article/unlike/${articleId}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );

      setLikedArticles(prevLikedArticles =>
        prevLikedArticles.filter(id => id !== articleId),
      );
    } catch (err) {
      console.error('Error unliking article:', err);
    }
  };

  const renderLikeIcon = articleId => {
    return likedArticles.includes(articleId) ? (
      <FavoriteIcon color="secondary" onClick={() => handleUnlike(articleId)} />
    ) : (
      <FavoriteBorderIcon onClick={() => handleLike(articleId)} />
    );
  };

  const handleImageLoad = id => {
    setImageLoading(prevState => ({...prevState, [id]: false}));
  };

  const handleNextTutorial = () => {
    if (tutorialIndex < tutorialImages.length - 1) {
      setTutorialIndex(tutorialIndex + 1);
    } else {
      setShowTutorial(false);
      updateFirstTimeLogin(); // Update the user's firstTimeLogin status in the backend
    }
  };

  const updateFirstTimeLogin = async () => {
    try {
      await axios.put(`/api/auth/updateUser`);
    } catch (error) {
      console.error('Error updating firstTimeLogin status:', error);
    }
  };

  const styles = {
    mainContainer: {
      marginTop: '0px',
      marginLeft: '35px',
      marginRight: '35px',
      marginBottom: '0px',
      fontFamily: fontFamilies.regular,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'fixed',
      top: 0, // Align to the top of the viewport
      left: 0, // Align to the left of the viewport
      right: 0, // Align to the right of the viewport
      margin: 0,
      backgroundColor: 'rgb(240, 240, 240)',
      width: '100%',
      zIndex: 1000, // Ensure it's above other elements
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    title: {
      fontFamily: fontFamilies.bold,
      color: '#3DDBD1',
      fontSize: '24px',
      margin: '15px',
    },
    scanIconButton: {
      cursor: 'pointer',
      background: 'none',
      border: 'none',
    },
    introContainer: {
      backgroundColor: '#3DDBD1',
      padding: '10px 20px',
      borderRadius: '20px',
      fontFamily: fontFamilies.regular,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      marginBottom: '50px',
      marginTop: '170px',
      color: 'white',
    },
    greetingContainer: {
      display: 'flex',
      flexDirection: 'row', // Explicitly set, although it's the default
      alignItems: 'center',
      justifyContent: 'space-between', // Adjusts space between the two child divs
      padding: '10px 20px',
      fontFamily: fontFamilies.regular,
      marginBottom: '10px',
    },
    greetingMsg: {
      fontSize: '18px',
      fontFamily: fontFamilies.semiBold,
    },
    greetingIcon: {
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
      marginTop: '-100px',
      marginRight: '-35px',
    },
    pointsContainer: {
      display: 'flex', // This enables flexbox
      flexDirection: 'column', // This stacks children vertically
      alignItems: 'center', // This centers children horizontally in the flex container
      justifyContent: 'center', // This centers children vertically in the flex container
      backgroundColor: '#9eede8',
      padding: '10px 20px',
      borderRadius: '20px',
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      marginBottom: '10px',
    },
    pointsLabel: {
      fontSize: '12px',
      fontFamily: fontFamilies.regular,
    },
    points: {
      fontSize: '18px',
      fontFamily: fontFamilies.semiBold,
      display: 'flex',
      alignItems: 'center', // Align text and SVG icon
    },
    coinIcon: {
      height: '25px',
      width: '25px',
      marginRight: '5px',
    },
    tutorialOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 1)',
      zIndex: 1001,
      cursor: 'pointer',
    },
    tutorialImage: {
      maxWidth: '100%',
      maxHeight: '100%',
      objectFit: 'contain',
      width: 'auto',
      height: 'auto',
    },
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    centerMode: true, // Enable center mode
    centerPadding: '40px',
    responsive: [],
  };

  for (let i = 1; i < 10; i++) {
    const breakpointJson = {
      breakpoint: window.innerWidth * i,
      settings: {
        slidesToShow: i,
        slidesToScroll: 1,
        infinite: false,
      },
    };
    settings.responsive[i] = breakpointJson;
  }

  const PointsRightArrowIcon = () => (
    <svg
      height="15" // Adjust the size as needed
      width="15" // Adjust the size as needed
      viewBox="0 0 20 20" // Ensure your viewBox matches the path's coordinates
    >
      <path
        d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z"
        fill="currentColor" // This will take the color from the 'color' style of the component
      />
    </svg>
  );

  const handleNavigateToPoints = () => {
    navigate('/points', {state: {showBackIcon: true}});
  };

  return (
    <>
      {showTutorial && (
        <div style={styles.tutorialOverlay} onClick={handleNextTutorial}>
          <img
            src={tutorialImages[tutorialIndex]}
            alt={`Tutorial step ${tutorialIndex + 1}`}
            style={styles.tutorialImage}
          />
        </div>
      )}
      <div style={styles.mainContainer}>
        <div style={styles.header}>
          <img
            src="/assets/images/logo.png"
            alt="CarbonMetA Carbon Point"
            style={styles.title}
          />
          <div style={styles.scanIconContainer}>
            <QrCodeScannerIcon
              style={{
                fontSize: '24px',
                marginTop: '15px',
                marginBottom: '15px',
              }}
              onClick={() => navigate('/scanQR', {state: {showBackIcon: true}})}
            />
            <NotificationsIcon
              style={{
                fontSize: '24px',
                marginTop: '15px',
                marginBottom: '15px',
                marginRight: '15px',
              }}
              onClick={() =>
                navigate('/notifications', {state: {showBackIcon: true}})
              }
            />
          </div>
        </div>

        <section>
          <div style={styles.introContainer}>
            <div style={styles.greetingContainer}>
              <div style={styles.greetingMsg}>
                {translate(language, 'home.greetings').replace(
                  '{userName}',
                  userInfo ? userInfo.firstName : 'User',
                )}
              </div>
              <div style={styles.greetingIcon}>
                <img src="/assets/images/greeting.png" alt="greeting" />
              </div>
            </div>
            <div
              style={styles.pointsContainer}
              onClick={handleNavigateToPoints}>
              <div style={styles.pointsLabel}>
                {translate(language, 'home.pointsLabel')}
                <PointsRightArrowIcon />
              </div>
              <div style={styles.points}>
                <ReactSVG
                  src="/assets/icons/coins.svg"
                  style={styles.coinIcon}
                />
                {userInfo.points}
              </div>
            </div>
          </div>
        </section>

        <HomeSlider
          title={translate(language, 'home.whatsNew')}
          items={whatsNewItems}
          loading={loading}
          imageLoading={imageLoading}
          renderLikeIcon={renderLikeIcon}
          language={language}
          navigate={navigate}
          styles={styles}
          settings={settings}
        />

        <HomeSlider
          title={translate(language, 'home.activities')}
          items={activityItems}
          loading={loading}
          imageLoading={imageLoading}
          renderLikeIcon={renderLikeIcon}
          language={language}
          navigate={navigate}
          styles={styles}
          settings={settings}
        />
      </div>

      <BottomNav />
    </>
  );
}

export default Home;
